import PropTypes from 'prop-types';
import React from 'react';
import CompanionIcon from '../../images/companion-animals-icon.png';
import LabIcon from '../../images/lab-animals-icon.png';
import WildlifeIcon from '../../images/wildlife-animals-icon.png';
import ZooIcon from '../../images/zoo-animals-icon.png';

const propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['zoo-animals', 'companion-animals', 'lab-animals', 'wildlife']),
};

const defaultProps = {
  type: 'zoo-animals',
};

const getIcon = (type, title) => {
  let image = null;
  switch (type) {
    case 'zoo-animals':
      image = ZooIcon;
      break;
    case 'companion-animals':
      image = CompanionIcon;
      break;
    case 'lab-animals':
      image = LabIcon;
      break;
    default:
      image = WildlifeIcon;
      break;
  }
  return (
    <picture>
      <source media="(max-width: 768px)" srcSet={image} />
      <img src={image} alt={title} />
    </picture>
  );
};

const HeroBox = ({ title, text, type }) => (
  <div className="c-hero-box">
    <div className="hero-box--image">{getIcon(type, text)}</div>
    <div className="hero-box--detail">
      <h4>{title}</h4>
      <hr className={type} />
      {/* <p>{text}</p> */}
    </div>
  </div>
);

HeroBox.propTypes = propTypes;
HeroBox.defaultProps = defaultProps;
export default HeroBox;
