import React from 'react';

const HomeServiceNumber = () => (
  <div className="home-service-number">
    <p className="h-text-center">
      If you have any questions regarding ZooPharm formulations, please call ZooPharm Customer
      Service at (866) 823-9314
    </p>
  </div>
);

export default HomeServiceNumber;
