import { graphql, Link } from 'gatsby';
import { shape } from 'prop-types';
import React from 'react';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';
import HeroBox from '../components/hero-box/hero-box';
import Hero from '../components/hero/hero';
import HomeServiceNumber from '../components/home-service-number/home-service-number';
import Layout from '../components/layout';
import NewsEvents from '../components/news-events/card-home';
import PostIterator from '../components/news-events/iterator';
import Logo from '../images/homelogo.png';

const propTypes = {
  data: shape({}).isRequired,
};

const IndexPage = ({ data }) => {
  const { edges: posts } = data.allStrapiPost;

  return (
    <Layout theme="home" index>
      <Hero index backgroundImage="index">
        <div className="home-logo">
          <picture>
            <source media="(max-width: 768px)" />
            <img src={Logo} alt="ZooPharm" />
          </picture>
        </div>
        <div className="flexcard-group">
          <div className="flexcard">
            <HeroBox
              title="Companion Animals"
              text="Exclusive formulations central to small animal veterinary clinics and animal hospitals for sustained release pain management and enhanced post-operative recovery."
              to="/categories/companion-animals/"
              type="companion-animals"
            />
          </div>
          <div className="flexcard">
            <HeroBox
              title="Lab Animals"
              text="Patented  formulations to ensure appropriate levels of sedation, analgesia and anesthesia are provided to meet latest Animal Welfare Act and IACUC guidelines."
              to="/categories/lab-animals/"
              type="lab-animals"
            />
          </div>
          <div className="flexcard">
            <HeroBox
              title="Wildlife"
              text="State-of-the-art immobilization drugs, anesthesia, analgesics with research-based support in advising and assisting wildlife medical professionals."
              to="/categories/wildlife/"
              type="wildlife"
            />
          </div>
          <div className="flexcard">
            <HeroBox
              title="Zoo Animals"
              text="Custom-compounded formulations to address the veterinary medical management and surgical care needs of diverse zoo and aquarium populations."
              to="/categories/zoo-animals/"
              type="zoo-animals"
            />
          </div>
        </div>
      </Hero>
      <HomeServiceNumber />
      <section className="content-wrapper c-news-events">
        <Grid>
          <GridItem sm={12}>
            <h2 className="h-text-center">- News & Events -</h2>
          </GridItem>
          <PostIterator
            posts={posts}
            render={(post, path, event) => (
              <GridItem key={post.slug} sm={4}>
                <NewsEvents
                  title={post.title}
                  text={post.shortDescription}
                  to={path}
                  event={event}
                />
              </GridItem>
            )}
          />
          <GridItem sm={12}>
            <p className="h-text-center">
              <Link to="/news-and-events/">VIEW ALL</Link>
            </p>
          </GridItem>
        </Grid>
      </section>
    </Layout>
  );
};

IndexPage.propTypes = propTypes;

export default IndexPage;

export const query = graphql`
  query {
    allStrapiPost(limit: 3, sort: { fields: strapiId, order: DESC }) {
      edges {
        node {
          slug
          title
          type
          shortDescription
          created_at
        }
      }
    }
  }
`;
